import React, { useEffect, useState } from "react";
import { fetchData, fetchAllData } from "../../helpers/externapi";
import MapBoxHospital from "../../Components/MapBoxHospital";
import { async } from "validate.js";

export default function HospitalPersonalDetails(props) {
    const profile = props.data;
    const [mocUrl, setMocUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [doctorData, setDoctorData] = useState([]);
    const [spocData, setSpocData] = useState([]);
    const [hospitalPolicies, setHospitalPolicies] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        const fetchDoctorData = async () => {
            try {
                const response = await fetchAllData(
                    `Doctor/GetByHospitalId/${profile.HospitalId}`
                );
                setDoctorData(response);
            } catch (error) {
                console.error("Error fetching doctor data:", error);
            }
        };

        const fetchSpocData = async () => {
            try {
                const spocresponse = await fetchAllData(`HospitalContact/GetByHospitalId/${profile.HospitalId}`);
                setSpocData(spocresponse);
            } catch (error) {
                console.error("Error fetching spoc data:", error);
            }
        }


        fetchSpocData();
        fetchDoctorData();
    }, []);

    useEffect(() => {
        const fetchHospitalPoliciesData = async () => {
            try {
                const response = await fetchAllData(
                    `HospitalPoliciesProvision/GetByHospitalId/${profile.HospitalId}`
                );
                setHospitalPolicies(response);
            } catch (error) {
                console.error("Error fetching doctor data:", error);
            }
        };
        fetchHospitalPoliciesData();
    }, []);

    useEffect(() => {
        const getMocUrl = async () => {
            const response = await fetchData('ConfigValues/all', { skip: 0, take: 0 });
            const bucketUrl = response && response.length > 0 && response.find(val => val.ConfigKey === "mouBucketURL");
            const imageUrl = response && response.length > 0 && response.find(val => val.ConfigKey === "hospitalImagesURL");
            setMocUrl(bucketUrl.ConfigValue);
            setImageUrl(imageUrl.ConfigValue);
        };

        getMocUrl();
    }, []);

    const handleUpdateLocation = async () => {
        try {
            const response = await fetchData('Geocoding/forward', { hospitalId: profile.HospitalId });
            window.location.reload();
        } catch (error) {
            console.error("Error fetching location data: ", error);
        }
    };


    const hospitalImages = profile.HospitalImages;



    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? hospitalImages.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === hospitalImages.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <>
            <div className="row mt-3 align-items-stretch">
                <div className="col-12 col-md-8 mb-3">
                    <div className="card p-2 p-md-3 h-100 d-flex flex-column">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            {profile.Image ?
                                <img src={`${imageUrl}${profile.Image}`} alt=''
                                    style={{ height: '100px', width: '120px', borderRadius: '10px' }}
                                />
                                : <img src={`${process.env.PUBLIC_URL}/assets/hospitaldummyimage.jpg`} alt=''
                                    style={{ height: '100px', width: '120px' }} />
                            }
                            {/* 
                            {hospitalImages.length > 0 ? (

                                <>
                                    <span
                                        onClick={handlePrev}
                                        style={{ color: '#0E94C3', fontSize: '45px', marginRight: '4px' }}
                                        className="cursor-pointer"
                                    >
                                        &#8249;
                                    </span>

                                    <img
                                        src={`${imageUrl}${hospitalImages[currentIndex].Image}`}
                                        alt={`Hospital ${hospitalImages[currentIndex].HospitalImagesId}`}
                                        style={{ height: '200px', width: '200px', borderRadius: '10px' }}
                                    />


                                    <span
                                        onClick={handleNext}
                                        style={{ color: '#0E94C3', fontSize: '45px', marginLeft: '4px' }}
                                        className="cursor-pointer"
                                    >
                                        &#8250;
                                    </span>
                                </>
                            ) : (
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/hospitaldummyimage.jpg`}
                                    alt="Dummy"
                                    className="h-52 w-72 object-cover rounded-xl"
                                />
                            )} */}

                            <div className="d-flex flex-column ms-1 ms-sm-3 ms-md-4">
                                <div className="d-flex flex-row align-items-center">
                                    <i className="fas fa-phone me-2 text-dark"></i>
                                    {profile.HospitalMobileNumber && profile.HospitalMobileNumber.length > 0 ? (
                                        <a href={`tel:${profile.HospitalMobileNumber}`}>{profile.HospitalMobileNumber}</a>
                                    ) : <span className="text-danger">Not exist</span>
                                    }
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <i class="fa-solid fa-blender-phone me-2 text-dark"></i>
                                    {profile.Landline && profile.Landline.length > 0 ? (
                                        <a href={`tel:${profile.Landline}`}>{profile.Landline}</a>
                                    ) : <span className="text-danger">Not exist</span>
                                    }
                                </div>

                                <div className="d-flex flex-row flex-wrap align-items-center">
                                    <i className="fas fa-envelope me-2 text-dark"></i>
                                    {profile.Email && profile.Email.length > 0 ? (
                                        <a href={`mailto:${profile.Email}`}>{profile.Email}</a>
                                    ) : <span className="text-danger">Not exist</span>
                                    }
                                </div>
                            </div>
                        </div>

                        <h5 className="text-dark fw-semibold my-3 fs-4">{profile.HospitalName}</h5>

                        <div className="d-flex flex-row align-items-center flex-wrap">
                            {profile.City && profile.City.length > 0 && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <i class="fa-solid fa-location-dot me-2 text-dark"></i> {profile.City}
                                </div>
                            )}

                            {profile.Pincode && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <i className="fas fa-thumbtack me-2 text-dark"></i> {profile.Pincode}
                                </div>
                            )}

                            {profile.Website && profile.Website.length > 0 && (
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <i className="fas fa-globe me-2 text-dark"></i>
                                    <a href={profile.Website} target="_blank" rel="noopener noreferrer">
                                        Hospital Website
                                    </a>
                                </div>
                            )}
                        </div>

                        <div className="d-flex flex-row align-items-center flex-wrap">
                            {profile.Mandal && profile.Mandal.length > 0 && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <strong className="me-1 text-dark">Mandal:</strong> {profile.Mandal}
                                </div>
                            )}
                            {profile.HospitalCode && profile.HospitalCode.length > 0 && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <strong className="me-1 text-dark">Code:</strong> {profile.HospitalCode}
                                </div>
                            )}
                            {profile.Specialization && profile.Specialization.length > 0 && (
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <strong className="me-1 text-dark">Specialization:</strong> {profile.Specialization}
                                </div>
                            )}
                        </div>

                        {profile.AddressLine1 && profile.AddressLine1.length > 0 && (
                            <div className="d-flex flex-row mb-2">
                                <strong className="me-1 text-dark">Address:</strong> {profile.AddressLine1}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-12 col-md-4 mb-3">
                    <div className="card h-100 d-flex flex-column">
                        {profile.Latitude && profile.Longitude && profile.Latitude.length > 0 && profile.Longitude.length > 0 ? (

                            <MapBoxHospital hospitalsData={[profile]} />

                        ) : (
                            <>
                                <h5 className="ps-2 ps-md-3 pt-2 pt-md-3 m-0 fw-semibold" style={{ color: '#008000' }}>
                                    Location Coordinates </h5>
                                <hr className="mb-0" />
                                <span className="text-danger text-center mt-md-5">Please update location Coordinates</span>
                                <div className="align-self-center">
                                    <button className="btn btn-primary" onClick={() => handleUpdateLocation()}>Update Location</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="row g-3">
                    {/* SPOCs Information */}
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm border-0 rounded-lg p-3 h-100">
                            <h5 className="mb-2" style={{ color: '#008000' }}>SPOCs INFORMATION</h5>
                            <div className="row">
                                {spocData && spocData.length > 0 ? (
                                    spocData.map((spoc, index) => {
                                        const badgeText = spoc.IsPrimary ? 'Primary' : spoc.IsSecondary ? 'Secondary' : '';
                                        const badgeColor = spoc.IsPrimary ? 'bg-success' : spoc.IsSecondary ? 'bg-warning' : '';
                                        return (
                                            <div key={spoc.HospitalContactId} className="col-12 mb-2">
                                                <div className="border p-2">
                                                    <div className="d-flex justify-content-between align-items-center mb-1">
                                                        <h6 className="m-0 text-success">{spoc.FullName}</h6>
                                                        {badgeText && (
                                                            <span className={`badge ${badgeColor} text-uppercase`}>
                                                                {badgeText}
                                                            </span>
                                                        )}
                                                    </div>
                                                    {spoc.Qualification && (
                                                        <p className="text-muted mb-1">({spoc.Qualification})</p>
                                                    )}
                                                    <p className="mb-1">
                                                        <strong>Primary Contact:</strong> {spoc.MobileNumber || <span className="text-danger">Not available</span>}
                                                    </p>
                                                    <p className="mb-1">
                                                        <strong>Address:</strong> {spoc.Address || <span className="text-danger">Not available</span>}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="col-12 text-center text-danger">
                                        No SPOC data available.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Doctor Information */}
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm border-0 rounded-lg p-3 h-100">
                            <h5 className="mb-2" style={{ color: '#008000' }}>Doctor Information</h5>
                            <div className="row">
                                {doctorData.length > 0 ? doctorData.map((doctor, index) => (
                                    <div key={index} className="col-12 mb-3">
                                        <div className="border p-2">
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <h6 className="m-0 text-success">{doctor.FullName || <span className="text-danger">Name not available</span>}</h6>
                                            </div>
                                            <p className="mb-1"><strong>Qualification:</strong> {doctor.Qualification || <span className="text-danger">Not available</span>}</p>
                                            <p className="mb-1"><strong>Primary Contact:</strong> {doctor.MobileNumber || <span className="text-danger">Not available</span>}</p>
                                            <p className="mb-1"><strong>Email:</strong> {doctor.Email || <span className="text-danger">Not available</span>}</p>
                                            <p className="mb-1"><strong>Specializations:</strong>
                                                {doctor.DoctorSpecialization && doctor.DoctorSpecialization.length > 0 ? (
                                                    <ul className="list-unstyled ms-3">
                                                        {doctor.DoctorSpecialization.map((specialization, specIndex) => (
                                                            <li key={specIndex}>{specialization.ServiceName}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span className="text-danger">No specializations listed</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                )) : (
                                    <div className="col-12 text-center text-danger">
                                        No doctor information available.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Hospital Policies Provision */}
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm border-0 rounded-lg p-3 h-100">
                            <h5 className="mb-2" style={{ color: '#008000' }}>Hospital Policies Provision</h5>
                            <div className="list-group">
                                {hospitalPolicies && hospitalPolicies.length > 0 ? (
                                    hospitalPolicies.map((policy, index) => (
                                        <div
                                            key={index}
                                            className="list-group-item d-flex justify-content-between align-items-center p-2 border mb-2"
                                        >
                                            <div>
                                                <span className="text-dark fw-bold">{policy.PoliciesType}</span>
                                                {policy.DiscountPercentage !== undefined && policy.DiscountPercentage > 0 && (
                                                    <span className="text-muted ms-2">
                                                        (Discount: {policy.DiscountPercentage}%)
                                                    </span>
                                                )}
                                            </div>
                                            <span
                                                className={`badge ${policy.IsActive ? "bg-success" : "bg-danger"}`}
                                            >
                                                {policy.IsActive ? "Active" : "Inactive"}
                                            </span>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center text-danger">
                                        No hospital policy data available.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Additional Information */}
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm border-0 rounded-lg p-3 h-100">
                            <h5 className="mb-2" style={{ color: '#008000' }}>ADDITIONAL INFORMATION</h5>
                            <div className="d-flex flex-column flex-md-row flex-wrap">
                                {[
                                    { label: "Aarogyasri", value: profile.Aarogyasri },
                                    { label: "Call to Front Desk", value: profile.CallToFrontDesk },
                                    { label: "Resident Counselling Fee", value: profile.PatientCounsellingFee },
                                    { label: "Menu Card for Diagnostics", value: profile.MenuCardForDiagnostics },
                                    { label: "Discount On Diagnostics", value: profile.DiscountOnDiagnostics },
                                    { label: "Is Free OP Consultation", value: profile.IsFreeOPConsultation },
                                    { label: "Is Agreement Received", value: profile.IsAgreementReceived ? 'Yes' : "Not Received" },
                                    {
                                        label: "MOU File", value: profile.MOUFileName ? (
                                            <a
                                                className="btn btn-sm btn-primary"
                                                href={`${mocUrl}${profile.MOUFileName}`}
                                                target="_blank"
                                                download
                                            >
                                                Download
                                            </a>
                                        ) : <span className="text-danger">Not given</span>
                                    }
                                ].map((item, index) => (
                                    <div key={index} className="d-flex flex-column flex-sm-row align-items-start mb-2 col-12 col-md-6">
                                        <strong className="text-dark me-2">{item.label}:</strong>
                                        <span>{item.value || <span className="text-danger">Not given</span>}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>








            </div >
        </>
    );
}