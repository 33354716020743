import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { fetchData } from "../../helpers/externapi";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CommonTables from "../../Commoncomponents/CommonTables";
import { TableSkeletonLoading } from "../../Commoncomponents/SkeletonLoading";
import DescriptionCell from "../../Commoncomponents/DescriptionCell";
import { isAction } from "@reduxjs/toolkit";

export default function List(props) {
  const [loading, setLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [activeButton, setActiveButton] = useState("all");
  // const [activeProducts, setActiveProducts] = useState([]);
  // const [inActiveProducts, setInActiveProducts] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const tableHeads = [
    "Product Name",
    "Short Description",
    "Is Combo",
    "Service Provider",
    "Base Price",
    "GST",
    "Total Amount",
  ];
  // const tableElementsActive = activeProducts.length > 0 ?
  //     activeProducts.map(data => ([
  //         <Link
  //             to={`/products/details/${data.ProductsId}`}
  //             className="text-start-important"
  //             style={{
  //                 whiteSpace: 'normal',
  //                 textAlign: 'start',
  //                 display: 'block',
  //             }}
  //             onClick={() => handleNavigation(`/products/details/${data.ProductsId}`)}
  //         >
  //             {data.ProductName}
  //         </Link>,
  //         <DescriptionCell description={data.ShortDescription} />,
  //         /*<div dangerouslySetInnerHTML={{ __html: data.KeyFeatures }} />*/
  //         data.IsCombo ? (
  //             <p className='badge bg-label-success'>COMBO</p>
  //         ) : (
  //             <p className='badge bg-label-warning'>INDIVIDUAL</p>
  //         ),
  //         data.ServiceProvider,
  //         data.BasePremium,
  //         data.GST,
  //         data.InsurancePremiums[0].TotalAmount,
  //     ])) : [];

  const tableElements =
    productsData.length > 0
      ? productsData.map((data) => [
          <Link
            to={`/products/details/${data.ProductsId}`}
            className="text-start-important"
            style={{
              whiteSpace: "normal",
              textAlign: "start",
              display: "block",
            }}
            onClick={() =>
              handleNavigation(`/products/details/${data.ProductsId}`)
            }
          >
            {data.ProductName}
          </Link>,
          <DescriptionCell description={data.ShortDescription} />,
          /*<div dangerouslySetInnerHTML={{ __html: data.KeyFeatures }} />*/
          data.IsCombo ? (
            <p className="badge bg-label-success">COMBO</p>
          ) : (
            <p className="badge bg-label-warning">INDIVIDUAL</p>
          ),
          data.ServiceProvider,
          data.BasePremium,
          data.GST,
          data.InsurancePremiums[0].TotalAmount,
        ])
      : [];
  // const tableElementsInActive = inActiveProducts.length > 0 ?
  //     inActiveProducts.map(data => ([
  //         <Link
  //             to={`/products/details/${data.ProductsId}`}
  //             className="text-start-important"
  //             style={{
  //                 whiteSpace: 'normal',
  //                 textAlign: 'start',
  //                 display: 'block',
  //             }}
  //             onClick={() => handleNavigation(`/products/details/${data.ProductsId}`)}
  //         >
  //             {data.ProductName}
  //         </Link>,
  //         <DescriptionCell description={data.ShortDescription} />,
  //         /*<div dangerouslySetInnerHTML={{ __html: data.KeyFeatures }} />*/
  //         data.IsCombo ? (
  //             <p className='badge bg-label-success'>COMBO</p>
  //         ) : (
  //             <p className='badge bg-label-warning'>INDIVIDUAL</p>
  //         ),
  //         data.ServiceProvider,
  //         data.BasePremium,
  //         data.GST,
  //         data.InsurancePremiums[0].TotalAmount,
  //     ])) : [];
  // const getProductcountData = async () => {
  //     setLoading(true);
  //     const productCountData = await fetchData(`CommonRowCount/GetTableRowCount`,
  //         { tableName: "Products", isActive: location.pathname.includes('inactive') ? false : true });
  //     const totalCount = productCountData[0]?.CountOfRecords || 0;
  //     setTotalCount(totalCount);
  //     setLoading(false);
  // };

  // useEffect(() => {
  //     getProductcountData();
  // }, [location]);

  const getProductData = async (filterType = "all") => {
    setLoading(true);
    try {
      const skip = (currentPage - 1) * perPage;
      const take = perPage;
      const isActive = location.pathname.includes("inactive") ? false : true;

      let filterParams = { skip, take, isActive };
      if (filterType === "combo") filterParams.IsCombo = true;
      if (filterType === "individual") filterParams.IsCombo = false;

      // Fetch total count for the selected filter
      const productCountData = await fetchData(
        "CommonRowCount/GetTableRowCount",
        {
          tableName: "Products",
          isActive,
          ...(filterType !== "all" && { IsCombo: filterParams.IsCombo }),
        }
      );
      const totalProductCount = productCountData[0]?.CountOfRecords || 0;
      setTotalCount(totalProductCount);

      // Fetch products based on the selected filter
      const productData = await fetchData(
        "Products/fetchProductsList/all",
        filterParams
      );

      const insurancePremiumData = await fetchData("InsurancePremium/all", {
        skip: 0,
        take: -1,
      });

      let filteredProducts = productData.map((product) => {
        const matchingPremium = insurancePremiumData.find(
          (premium) => premium.ProductsId === product.ProductsId
        );

        return {
          ...product,
          BasePremium: matchingPremium ? matchingPremium.BasePremium : null,
          GST: matchingPremium ? matchingPremium.GST : null,
          TotalAmount: matchingPremium ? matchingPremium.TotalAmount : null,
        };
      });

      setProductsData(filteredProducts);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, [currentPage, perPage, filterCriteria, location.pathname]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleNavigation = (path) => {
    setIsNavigating(true);
    navigate(path);
    setIsNavigating(false);
  };
  const showComboProducts = () => {
    getProductData("combo");
  };

  const showIndividualProducts = () => {
    getProductData("individual");
  };

  const showAllProducts = () => {
    getProductData("all");
  };
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    // Call the corresponding function based on the button type
    if (buttonType === "all") {
      showAllProducts();
    } else if (buttonType === "individual") {
      showIndividualProducts();
    } else if (buttonType === "combo") {
      showComboProducts();
    }
  };

  return (
    <>
      {loading ? (
        <TableSkeletonLoading />
      ) : !isDataLoaded ? (
        <TableSkeletonLoading />
      ) : (
        <>
          {(loading || isNavigating) && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <Card style={{ margin: "5px", borderRadius: "10px" }}>
            <CardContent>
              <div className="d-flex justify-content-start p-3">
                <button
                  className={`btn me-2 ${
                    activeButton === "all"
                      ? "btn-success"
                      : "btn-outline-success"
                  }`}
                  onClick={() => handleButtonClick("all")}
                >
                  All Products
                </button>
                <button
                  className={`btn me-2 ${
                    activeButton === "individual"
                      ? "btn-success"
                      : "btn-outline-success"
                  }`}
                  onClick={() => handleButtonClick("individual")}
                >
                  Individual Products
                </button>
                <button
                  className={`btn me-2 ${
                    activeButton === "combo"
                      ? "btn-success"
                      : "btn-outline-success"
                  }`}
                  onClick={() => handleButtonClick("combo")}
                >
                  Combo Products
                </button>
              </div>

              <div style={{ overflowX: "auto" }}>
                <CommonTables
                  tableHeads={tableHeads}
                  tableData={tableElements}
                  perPage={perPage}
                  currentPage={currentPage}
                  perPageChange={handlePerPageChange}
                  pageChange={handlePageChange}
                  totalCount={totalCount}
                />
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}
