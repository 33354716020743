import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { fetchAllData } from "../../helpers/externapi";

export default function ProductPersonalDetails(props) {
  const [profile, setProfile] = useState(props.data || []);
  const [otherCharges, setOtherCharges] = useState([]);
  const [productOffers, setProductsOffers] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const id = useParams();
  const [comboList, setComboList] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
    const modal = new window.bootstrap.Modal(
      document.getElementById("exLargeModal")
    );
    modal.show();
  };

  useEffect(() => {
    const fetchProductsDetails = async () => {
      setLoading(true);
      try {
        const response = await fetchAllData(
          `Products/GetAllProductDetailsById/${id.Id}`
        );
        setProductsOffers(response[0].ProductsOffers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchProductsDetails();
  }, [id.Id]);

  useEffect(() => {
    const fetchOtherChargesData = async () => {
      setLoading(true);
      try {
        const response = await fetchAllData(
          `OtherCharges/GetOtherChargesDetailsByProductsId/${id.Id}`
        );
        setOtherCharges(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchOtherChargesData();
  }, [id.Id]);

  useEffect(() => {
    if (profile.length > 0 && profile[0].IsCombo) {
      const getSelectedProducts = async () => {
        const response = await fetchAllData(
          `ComboProducts/FetchComboProducts/${profile[0].ProductsId}`
        );
        setComboList(response);
      };

      getSelectedProducts();
    }
  }, [profile]);

  const handleEditForm = () => {
    navigate("/products/new", { state: { profile, otherCharges } });
  };

  const handleBackToList = () => {
    navigate("/products/list");
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card shadow-lg border-0 rounded-4 p-4">
            {profile?.length ? (
              <div>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                  <h2 className="fw-bold text-primary mb-3 mb-md-0">
                    {profile[0]?.ProductName}
                  </h2>
                  <span
                    className={`badge ${
                      profile[0]?.IsCombo ? "bg-success" : "bg-warning"
                    } rounded-pill px-3 py-2`}
                  >
                    {profile[0]?.IsCombo
                      ? "Combo Product"
                      : "Individual Product"}
                  </span>
                </div>

                {/* Product Details */}
                <div className="row mt-4 g-0">
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush shadow-sm rounded-3 overflow-hidden mb-0">
                      <li className="list-group-item p-2">
                        <strong>Maximum Adult:</strong>{" "}
                        {profile[0]?.MaximumAdult}
                      </li>
                      <li className="list-group-item p-2">
                        <strong>Maximum Child:</strong>{" "}
                        {profile[0]?.MaximumChild}
                      </li>
                      <li className="list-group-item p-2">
                        <strong>Minimum Age:</strong> {profile[0]?.MinimumAge}
                      </li>
                      <li className="list-group-item p-2">
                        <strong>Maximum Age:</strong> {profile[0]?.MaximumAge}
                      </li>
                      <li className="list-group-item p-2">
                        <strong>Children Age:</strong> {profile[0]?.ChildrenAge}
                      </li>
                      <li className="list-group-item p-2">
                        <strong>Service Provider:</strong>{" "}
                        {profile[0]?.ServiceProvider}
                      </li>
                      <li className="list-group-item p-2">
                        <strong>Short Description:</strong>{" "}
                        {profile[0]?.ShortDescription}
                      </li>
                    </ul>
                  </div>

                  {/* Key Features */}
                  <div className="col-md-6">
                    <h5 className="text-primary fw-bold m-3 mt-0">
                      Key Features
                    </h5>
                    <ul className="ps-3 m-3" style={{ listStyleType: "none" }}>
                      {stripHtmlTags(profile[0]?.KeyFeatures)
                        .split(/[.,;]/)
                        .filter((feature) => feature.trim())
                        .map((feature, index) => (
                          <li key={index} className="text-muted">
                            <span className="text-success">✔</span>{" "}
                            {feature.trim()}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="row mt-4 g-0">
                  {profile[0]?.IsCombo && comboList?.length ? (
                    <div className="col-md-6">
                      <div className="card shadow-lg border-0 rounded-4 p-2">
                        <h5 className="card-header bg-light text-primary fw-bold">
                          Combo Products
                        </h5>
                        <ul className="list-group list-group-flush shadow-sm rounded-3 overflow-hidden mb-0">
                          {comboList.map((item) => (
                            <li
                              key={item?.ProductsId}
                              className="list-group-item p-2"
                            >
                              <a
                                href={`/products/details/${item?.ProductsId}`}
                                target="_blank"
                                className="text-decoration-none text-dark"
                              >
                                {item?.ProductName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : null}

                  {profile[0]?.LongDescription && (
                    <div className="col-md-6 p-4">
                      <div className="mt-2">
                        <h5 className="text-primary fw-bold">Description</h5>
                        <p className="text-muted bg-light rounded-3 mb-1">
                          {stripHtmlTags(profile[0]?.LongDescription)}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {productOffers?.length ? (
                  <div className="card shadow-lg border-0 rounded-4 p-2 mt-3">
                    <h5 className="card-header bg-light text-primary fw-bold">
                      Product Offers
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-hover table-bordered rounded-3 overflow-hidden mb-1">
                        <thead className="bg-primary text-white">
                          <tr>
                            <th>Member Type</th>
                            <th>Offer Amount</th>
                            <th>Offer Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productOffers.map((item, index) => (
                            <tr key={index}>
                              <td>{item.MemberType}</td>
                              <td>{item.OfferAmount}</td>
                              <td>{item.OfferPercentage}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                {profile[0]?.InsurancePremiums?.length ? (
                  <div className="card shadow-lg border-0 rounded-4 p-2 mt-3">
                    <h5 className="card-header bg-light text-primary fw-bold">
                      Insurance Premium
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-hover table-bordered rounded-3 overflow-hidden mb-1">
                        <thead className="bg-primary text-white">
                          <tr>
                            <th>Minimum Age</th>
                            <th>Maximum Age</th>
                            <th>Base Premium</th>
                            <th>GST</th>
                            <th>Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {profile[0]?.InsurancePremiums.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.MinimumAge}</td>
                              <td>{item?.MaximumAge}</td>
                              <td>{item?.BasePremium}</td>
                              <td>{item?.GST}</td>
                              <td>{item?.TotalAmount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                {otherCharges?.length ? (
                  <div className="card shadow-lg border-0 rounded-4 p-2 mt-3">
                    <h5 className="card-header bg-light text-primary fw-bold">
                      Other Charges
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-hover table-bordered rounded-3 overflow-hidden mb-1">
                        <thead className="bg-primary text-white">
                          <tr>
                            <th>Label Name</th>
                            <th>Value</th>
                            <th>Is Fixed</th>
                          </tr>
                        </thead>
                        <tbody>
                          {otherCharges.map((item, index) => (
                            <tr key={index}>
                              <td>{item.LabelName}</td>
                              <td>{item.Value}</td>
                              <td>{item.IsFixed ? "Yes" : "No"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {/* Action Buttons */}
    <div className="d-flex justify-content-between my-4">
        <button onClick={handleBackToList} className="btn btn-outline-primary px-4 py-2 rounded-pill">
            ⬅ Back to List
        </button>
        <button onClick={handleEditForm} className="btn btn-primary px-4 py-2 rounded-pill">
            Edit Product
        </button>
    </div>


      </div>
    </>
  );
}
