import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import ConfirmationDialogDelete from "../../Components/ConfirmationDialogDelete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import moment from "moment";

export default function EventPersonalDetails(props) {
  const profile = props.data;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = (eventId) => {
    setConfirmationData({
      title: "Delete Event",
      message: "Are you sure you want to delete this event?",
      onConfirm: () => confirmhandleDelete(eventId),
    });
    setConfirmationOpen(true);
  };

  const confirmhandleDelete = async (eventId) => {
    setConfirmationOpen(false);
    try {
      setLoading(true);
      console.log(`Deleted event with ID: ${eventId}`);
      setSnackbarMessage("Event deleted successfully.");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting event:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="container mt-5">
      <div className="card p-4 border-0">
        {/* Header Section */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="text-primary">{profile.EventName}</h4>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(profile.EventId)}
          >
            <i className="bi bi-trash3-fill me-1"></i> Delete
          </button>
        </div>

        {/* Event Details Section */}
        <div className="row gy-4">
          {/* Event Type */}
          {profile.EventType && (
            <div className="col-md-4 d-flex align-items-center">
              <i className="bi bi-briefcase-fill me-2 text-dark fs-5"></i>
              <span>{profile.EventType}</span>
            </div>
          )}

          {/* Event Date */}
          {profile.EventDate && (
            <div className="col-md-4 d-flex align-items-center">
              <i className="bi bi-calendar2-event me-2 text-dark fs-5"></i>
              <span>{moment(profile.EventDate).format("DD-MMM-YYYY")}</span>
            </div>
          )}

          {/* Location */}
          {profile.Location && (
            <div className="col-md-4 d-flex align-items-center">
              <i className="bi bi-geo-alt-fill me-2 text-dark fs-5"></i>
              <span>{profile.Location}</span>
            </div>
          )}

          {/* Combined Address */}
          {(profile.AddressLine1 ||
            profile.AddressLine2 ||
            profile.City ||
            profile.Pincode) && (
            <div className="col-md-8 d-flex align-items-center">
              <i className="bi bi-house-fill me-2 text-dark fs-5"></i>
              <span>
                {[profile.AddressLine1, profile.AddressLine2, profile.City, profile.Pincode]
                  .filter(Boolean)
                  .join(", ")}
              </span>
            </div>
          )}

          {/* Map View */}
          {profile.MapView && (
            <div className="col-md-4 d-flex align-items-center">
              <i className="bi bi-map-fill me-2 text-dark fs-5"></i>
              <a
                href={profile.MapView}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none text-primary"
              >
                View on Map
              </a>
            </div>
          )}
        </div>

        {/* Snackbar and Confirmation */}
        <TableContainer component={Paper}>
          <ConfirmationDialogDelete
            open={confirmationOpen}
            title={confirmationData.title}
            message={confirmationData.message}
            onConfirm={confirmationData.onConfirm}
            onCancel={() => setConfirmationOpen(false)}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </TableContainer>
      </div>
    </div>
  );
}
