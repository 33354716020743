import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, fetchAllData } from "../../helpers/externapi";
import CommonTables from "../../Commoncomponents/CommonTables";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import Modal from "react-modal";
import { TableSkeletonLoading } from "../../Commoncomponents/SkeletonLoading";
import { formatDate } from "../../Commoncomponents/CommonComponents";
import DescriptionCell from "../../Commoncomponents/DescriptionCell";
import { MultiSelect } from "react-multi-select-component";

export default function PreviousList(district) {
  const [loading, setLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [consultationList, setConsultationList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toogleFeedback, setToggleFeedback] = useState("Customer");
  const [customerFeedback, setCustomerFeedback] = useState("");
  const [hospitalFeedback, setHospitalFeedback] = useState("");
  const [bookingConsultationId, setBookingConsultationId] = useState();
  const [successHosFeedback, setSuccessHosFeedback] = useState("");
  const [successCusFeedback, setSuccessCusFeedback] = useState("");
  const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
  const [paidAmount, setPaidAmount] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [selectedStates, setSelectedStates] = React.useState([]);
  const [selectedDistricts, setSelectedDistricts] = React.useState([]);
  const [selectedMandals, setSelectedMandals] = useState([]);
  const [statesMultiSelect, setStatesMultiSelect] = React.useState();
  const [districtsMultiSelect, setDistricsMultiSelect] = React.useState();
  const [isDisableApply, setIsDisableApply] = useState(true);
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let UserId = localStorage.getItem("UserId");

  const tableHeads = [
    "Full Name",
    "Mobile Number",
    "Appointment Date",
    "Hospital Name",
    "Appointment",
    "Customer Feedback",
    "Hospital Feedback",
    "Feedback",
  ];

  const tableElements =
    consultationList && consultationList.length > 0
      ? consultationList.map((data) => [
          <Link
            to={
              data.MemberTypeId === 1
                ? `/distributor/details/${data.MemberId}`
                : `/customers/details/${data.MemberId}`
            }
          >
            {data.Name}
          </Link>,
          <p>{data.MobileNumber}</p>,
          <p>{formatDate(data.AppointmentDate).slice(0, 11)}</p>,
          <a href={`/hospitals/details/${data.HospitalId}`} target="_blank">
            {data.HospitalName}
          </a>,
          <p>{data.Appointment}</p>,
          <DescriptionCell description={data.CustomerFeedBack} />,
          <DescriptionCell description={data.HospitalFeedBack} />,
          <div className="align-items-stretch">
            <button
              className="btn btn-primary btn-sm mb-1 w-100"
              data-bs-toggle="modal"
              data-bs-target="#customerFeedbackModal"
              onClick={() => openFeedbackForm("Customer", data)}
            >
              Customer
            </button>

            <button
              className="btn btn-success btn-sm w-100"
              data-bs-toggle="modal"
              data-bs-target="#hospitalFeedbackModal"
              onClick={() => openFeedbackForm("Hospital", data)}
            >
              Hospital
            </button>
          </div>,
        ])
      : [];

  const openFeedbackForm = (feedbackType, data) => {
    if (feedbackType === "Customer") {
      setToggleFeedback("Customer");
      setCustomerFeedback(
        data.CustomerFeedBack === null ? "" : data.CustomerFeedBack
      );
      setPaidAmount(data.PaidAmount || 0);
      setTotalAmount(data.TotalAmount || 0);
      setSuccessCusFeedback("");
    } else if (feedbackType === "Hospital") {
      setToggleFeedback("Hospital");
      setHospitalFeedback(
        data.HospitalFeedBack === null ? "" : data.HospitalFeedBack
      );
      setSuccessHosFeedback("");
    }
    setBookingConsultationId(data.BookingConsultationId);
  };

  const getConsultationList = async () => {
    try {
      setLoading(true);

      const skip = (currentPage - 1) * perPage;
      const take = perPage;

      let response;
      let responseCount;

      if (filterCriteria.length > 0) {
        response = await fetchData(
          "BookingConsultation/GetDataByHospitalLocation",
          {
            skip,
            take,
            filter: filterCriteria,
          }
        );

        responseCount = await fetchData(
          "BookingConsultation/GetDataByHospitalLocationCount",
          { skip: 0, take: 0, filter: filterCriteria }
        );
      } else {
        response = await fetchData(
          "BookingConsultation/BookingConsultationListBasedonCurrentDate",
          { skip, take }
        );
        responseCount = await fetchData(
          "BookingConsultation/BookingConsultationListBasedonCurrentDateCount",
          { skip: 0, take: 0 }
        );
      }

      setConsultationList(response);
      setIsDataLoaded(true);
      setTotalCount(responseCount[0].totalCount);
    } catch (e) {
      console.error("Error fetching Concultation list: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getStates = async () => {
      setLoading(true);
      const statesData = await fetchData("States/all", { skip: 0, take: 0 });
      const statesArray =
        statesData &&
        statesData.map((item) => ({
          label: item.StateName,
          value: item.StateId,
        }));
      setStatesMultiSelect(statesArray);
      setLoading(false);
    };

    const getDistricts = async (event) => {
      setLoading(true);
      const districtsData = await fetchData("Districts/all", {
        skip: 0,
        take: 0,
      });
      const districtsArray =
        districtsData &&
        districtsData.map((item) => ({
          label: item.DistrictName,
          value: item.DistrictId,
        }));
      setDistricsMultiSelect(districtsArray);
      setLoading(false);
    };

    getStates();
    getDistricts();
  }, []);

  useEffect(() => {
    if (
      selectedStates.length === 0 &&
      selectedDistricts.length === 0 &&
      selectedMandals.length === 0
    ) {
      setIsDisableApply(true);
    } else {
      setIsDisableApply(false);
    }
  }, [selectedStates, selectedDistricts, selectedMandals]);

  // const getConsultationListByHospital = async () => {
  //     try {
  //         setLoading(true);
  //         const skip = (currentPage - 1) * perPage;
  //         const take = perPage;

  //         let responspeByHos;
  //         let responspeByHosCount;

  //         if (filterCriteria.length > 0) {
  //             responspeByHos = await fetchData("BookingConsultation/GetDataByHospitalLocation", {
  //                 skip,
  //                 take,
  //                 filter: filterCriteria
  //             });
  //         } else {

  //             responspeByHos = await fetchData("BookingConsultation/GetDataByHospitalLocation", {
  //                 skip, take,
  //                 filter: [{ key: "DistrictId", value: district.district, operator: "IN" }]
  //             });

  //             responspeByHosCount = await fetchData("BookingConsultation/GetDataByHospitalLocation", {
  //                 skip: 0, take: 0,
  //                 filter: [{ key: "DistrictId", value: district.district, operator: "IN" }]
  //             });
  //         }

  //         setConsultationList(responspeByHos);
  //         setIsDataLoaded(true);
  //         setTotalCount(responspeByHosCount.length);
  //     } catch (e) {
  //         console.error("Error -BookingConsultation/GetDataByHospitalLocation: ", e);
  //     } finally {
  //         setLoading(false);
  //     }
  // };

  useEffect(() => {
    getConsultationList();
  }, [currentPage, perPage, filterCriteria]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMandalSelect = (event) => {
    const selectedMandal = event.target.value;
    if (selectedMandal === "") {
      setSelectedMandals([]);
    } else {
      setSelectedMandals([...selectedMandals, selectedMandal]);
    }
  };

  const onSubmitFeedback = async (e, feedbackType) => {
    e.preventDefault();
    try {
      setSubmitFeedbackLoading(true);
      const feedbackData =
        feedbackType === "Customer"
          ? {
              customerfeedback: customerFeedback,
              bookingconsultationid: bookingConsultationId,
              UserId,
              PaidAmount: paidAmount,
              TotalAmount: totalAmount,
            }
          : {
              hospitalfeedback: hospitalFeedback,
              bookingconsultationid: bookingConsultationId,
              UserId,
            };

      const endpoint =
        feedbackType === "Customer"
          ? "BookingConsultation/UpdateCustomerFeedBack"
          : "BookingConsultation/UpdateHospitalFeedBack";

      const resFeedback = await fetchData(endpoint, feedbackData);

      if (resFeedback && resFeedback.status) {
        setSnackbarOpen(true);
        feedbackType === "Customer"
          ? setSnackbarMessage(resFeedback.message)
          : setSnackbarMessage(resFeedback.message);

        // Reload the page after 3 seconds if the feedback is successfully submitted
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      console.error(`Error submitting ${feedbackType} feedback:`, e);
    } finally {
      setSubmitFeedbackLoading(false);
    }
  };

  const handleResetFeedback = () => {
    if (toogleFeedback === "Customer") {
      setCustomerFeedback("");
    } else {
      setHospitalFeedback("");
    }
  };

  const clearFilters = async () => {
    setSelectedStates([]);
    setSelectedDistricts([]);
    setSelectedMandals([]);
    setIsDisableApply(true);
    document.getElementById("mandal-input").value = "";
    setFilterCriteria([]);
    getConsultationList();
  };

  const applyFilter = async () => {
    setLoading(true);
    //const mobileNo = document.getElementById("mobile-input").value;
    const selectedStateIds =
      selectedStates && selectedStates.map((state) => state.value);
    const selectedDistrictIds =
      selectedDistricts && selectedDistricts.map((district) => district.value);
    const mandal = document.getElementById("mandal-input").value;

    const filterCriteria = [];

    //if (name.trim() !== "") {
    //    filterCriteria.push({
    //        key: "Name",
    //        value: name,
    //        operator: "LIKE"
    //    });
    //}
    //if (mobileNo.trim() !== "") {
    //    filterCriteria.push({
    //        key: "MobileNumber",
    //        value: mobileNo,
    //        operator: "LIKE"
    //    });
    //}
    if (selectedStateIds.length > 0) {
      filterCriteria.push({
        key: "StateId",
        value: selectedStateIds.join(","),
        operator: "IN",
      });
    }
    if (selectedDistrictIds.length > 0) {
      filterCriteria.push({
        key: "DistrictId",
        value: selectedDistrictIds.join(","),
        operator: "IN",
      });
    }

    if (mandal.trim() !== "") {
      filterCriteria.push({
        key: "Mandal",
        value: mandal,
        operator: "LIKE",
      });
    }

    try {
      const filterData = await fetchData(
        "BookingConsultation/GetDataByHospitalLocation",
        {
          skip: 0,
          take: perPage,
          filter: filterCriteria,
        }
      );

      const fiterCount = await fetchData(
        "BookingConsultation/GetDataByHospitalLocationCount",
        {
          skip: 0,
          take: 0,
          filter: filterCriteria,
        }
      );

      setPerPage(perPage);
      setCurrentPage(1);

      setFilterCriteria(filterCriteria);
      setConsultationList(filterData);
      setTotalCount(fiterCount.length);
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterUI = () => (
    <>
      {(selectedStates.length > 0 ||
        selectedDistricts.length > 0 ||
        selectedMandals.length > 0) && (
        <div className="card p-1 my-1 sticky-top" style={{ zIndex: 1 }}>
          <div
            className="select2-primary mx-2 mb-2"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <>
              <strong style={{ marginRight: "5px" }}>Filter Criteria - </strong>

              {selectedStates.length > 0 && (
                <div
                  style={{
                    marginRight: "10px",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong style={{ marginRight: "5px" }}>State : </strong>
                  {selectedStates.map((state, index) => (
                    <span key={state.value} className="selected-option-button">
                      {state.label}
                      {index !== selectedStates.length - 1 && ", "}
                    </span>
                  ))}
                </div>
              )}
              {selectedDistricts.length > 0 && (
                <div
                  style={{
                    marginRight: "10px",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong style={{ marginRight: "5px" }}>District : </strong>
                  {selectedDistricts.map((district, index) => (
                    <span
                      key={district.value}
                      className="selected-option-button"
                    >
                      {district.label}
                      {index !== selectedDistricts.length - 1 && ", "}
                    </span>
                  ))}
                </div>
              )}
              {selectedMandals.length > 0 && (
                <div
                  style={{
                    marginRight: "10px",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong style={{ marginRight: "5px" }}>Mandal : </strong>
                  <span className="selected-option-button">
                    {selectedMandals[selectedMandals.length - 1]}
                  </span>
                </div>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
     {loading ? (
  <TableSkeletonLoading />
) : !isDataLoaded ? (
  <TableSkeletonLoading />
) : (
  <>
    {filterUI()}

    <div className="accordion" id="accordionExample">
      <div
        className="modal fade"
        id="filterModal"
        tabIndex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="modal-title" id="filterModalLabel">
                  Filters
                </h5>
                <div className="d-flex gap-2">
                  <button
                    type="reset"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={clearFilters}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={applyFilter}
                    disabled={isDisableApply}
                  >
                    Apply
                  </button>
                </div>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="accordion-body">
                <div className="row mb-3">
                  {/* States Select */}
                  <div className="col-12 col-md-6">
                    <label htmlFor="select2Success" className="form-label">
                      States
                    </label>
                    <div className="select2-primary">
                      {statesMultiSelect && (
                        <MultiSelect
                          options={statesMultiSelect}
                          value={selectedStates}
                          onChange={setSelectedStates}
                          className="w-100"
                        />
                      )}
                    </div>
                  </div>

                  {/* Districts Select */}
                  <div className="col-12 col-md-6">
                    <label htmlFor="select2Success" className="form-label">
                      Districts
                    </label>
                    <div className="select2-primary">
                      {districtsMultiSelect && (
                        <MultiSelect
                          options={districtsMultiSelect}
                          value={selectedDistricts}
                          onChange={setSelectedDistricts}
                          className="w-100"
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Mandal Input */}
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="mandal-input" className="form-label">
                      Mandal
                    </label>
                    <input
                      type="text"
                      value={selectedMandals[selectedMandals.length - 1]}
                      id="mandal-input"
                      className="form-control"
                      maxLength="50"
                      onChange={handleMandalSelect}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="modal-footer">
              <button
                type="reset"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={clearFilters}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={applyFilter}
                disabled={isDisableApply}
              >
                Apply
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>



          
          <div className="card mt-2">
            {consultationList && consultationList.length > 0 ? (
              <CommonTables
                tableHeads={tableHeads}
                tableData={tableElements}
                perPage={perPage}
                currentPage={currentPage}
                perPageChange={handlePerPageChange}
                pageChange={handlePageChange}
                totalCount={totalCount}
              />
            ) : (
              <h4 className="text-danger text-center m-3">No records exists</h4>
            )}

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </>
      )}

      {/* Customer Feedback Modal */}
      <div
        className="modal fade"
        id="customerFeedbackModal"
        tabIndex="-1"
        aria-hidden="true"
        aria-labelledby="customerFeedbackModalLabel"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="customerFeedbackModalLabel">
                Customer Feedback
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                style={{ height: "100px" }}
                className="mb-1 w-100"
                placeholder="Enter Customer Feedback"
                value={customerFeedback}
                onChange={(e) => setCustomerFeedback(e.target.value)}
              />
              <div className="row mb-3">
                <div className="col-12 col-sm-6 d-flex flex-column">
                  <label className="text-dark" htmlFor="paidAmount">
                    Enter Paid Amount
                  </label>
                  <input
                    type="number"
                    id="paidAmount"
                    value={paidAmount}
                    onChange={(e) => setPaidAmount(e.target.value)}
                    onKeyDown={(e) => {
                      if (["e", "E", "+", "-"].includes(e.key))
                        e.preventDefault();
                    }}
                  />
                </div>

                <div className="col-12 col-sm-6 d-flex flex-column">
                  <label className="text-dark" htmlFor="totalAmount">
                    Enter Total Amount
                  </label>
                  <input
                    type="number"
                    id="totalAmount"
                    value={totalAmount}
                    onChange={(e) => setTotalAmount(e.target.value)}
                    onKeyDown={(e) => {
                      if (["e", "E", "+", "-"].includes(e.key))
                        e.preventDefault();
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <button
                  className="btn btn-primary align-self-start me-2"
                  onClick={(e) => onSubmitFeedback(e, "Customer")}
                  style={{ minWidth: "100px", minHeight: "38px" }}
                >
                  {submitFeedbackLoading ? (
                    <div className="spinner-border text-white" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  className="btn btn-secondary align-self-start me-2"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger align-self-start"
                  onClick={handleResetFeedback}
                >
                  Reset
                </button>
              </div>
              {successCusFeedback && (
                <span className="text-success mt-2 text-center">
                  {successCusFeedback}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Hospital Feedback Modal */}
      <div
        className="modal fade"
        id="hospitalFeedbackModal"
        tabIndex="-1"
        aria-hidden="true"
        aria-labelledby="hospitalFeedbackModalLabel"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="hospitalFeedbackModalLabel">
                Hospital Feedback
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <textarea
                  style={{ height: "100px", width: "700px" }}
                  className="mb-3"
                  value={hospitalFeedback}
                  onChange={(e) => setHospitalFeedback(e.target.value)}
                />

                <div className="d-flex flex-row align-items-center">
                  <button
                    className="btn btn-primary align-self-start me-2"
                    onClick={(e) => onSubmitFeedback(e, "Hospital")}
                    style={{ minWidth: "100px", minHeight: "38px" }}
                  >
                    {submitFeedbackLoading ? (
                      <div className="spinner-border text-white" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    className="btn btn-secondary align-self-start me-2"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger align-self-start"
                    onClick={handleResetFeedback}
                  >
                    Reset
                  </button>
                </div>
                {successHosFeedback && (
                  <span className="text-success mt-2 text-center">
                    {successHosFeedback}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
